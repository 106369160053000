import Modal from '../../core/Modal'
import React from 'react'
import { ContainedButton, OutlineButton } from '../../core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { floorNumber, formatMoney } from '../../../util/number'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { renewSessionTimeLimit, useSessionTimeLimitCheck } from '../../../lib/account'
import { useUser } from '../../../lib/user'
import Link from 'next/link'
import { faTreePalm } from '@fortawesome/pro-duotone-svg-icons'
import Lottie from 'react-lottie'
import breakTime from '../../../animations/lottie/break-time.json'

const SessionTimeLimitModal = () => {
  const { t } = useTranslation('common')
  const currency = useUserCurrency()
  const { user } = useUser()
  const { sessionTimeLimit, mutateSessionTimeLimit } = useSessionTimeLimitCheck(user)

  const options = {
    loop: true,
    autoplay: true,
    animationData: breakTime,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const onContinue = async () => {
    await renewSessionTimeLimit()
    await mutateSessionTimeLimit()
  }

  if (sessionTimeLimit == null) return <></>

  return (
    <Modal show>
      <div className="session-time-limit-modal flex justify-center items-center flex-col space-y-4 max-w-[360px]">
        <span className={'text-lg font-bold'}>
          <FontAwesomeIcon icon={faTreePalm} className={'text-teal-600 mr-2'} />
          {t('common:SessionTimeLimitModal.title')}
        </span>

        <div className={'w-full flex max-w-[250px] -mt-24'}>
          <Lottie options={options} />
        </div>

        <pre className={'text-sm whitespace-pre-wrap'}>
          <Trans
            i18nKey={'common:SessionTimeLimitModal.timeSpent'}
            components={[
              <span className={'font-bold text-yellow-400'} />,
              <span className={'font-bold text-yellow-400'} />,
            ]}
            values={{
              playTime: floorNumber(sessionTimeLimit.loggedInSince / 1000 / 60, 0),
              username: user?.profile?.firstName ?? user?.logonName,
            }}
          />
        </pre>

        {sessionTimeLimit.gains < 0 && (
          <div
            className={
              'w-full p-2 text-center text-sm !mb-2 !mt-6 bg-yellow-400 bg-opacity-30 text-yellow-400 border-[1px] border-yellow-400 rounded'
            }
          >
            <Trans
              i18nKey={'common:SessionTimeLimitModal.moneyLost'}
              components={[<span className={'font-bold'} />]}
              values={{ lostAmount: formatMoney(sessionTimeLimit.gains, currency) }}
            />
          </div>
        )}

        <div className={'flex space-x-2 w-full'}>
          <Link href="/account/logout">
            <OutlineButton borderColor="border-transparent">{t('common:SessionTimeLimitModal.logout')}</OutlineButton>
          </Link>
          <ContainedButton onClick={onContinue} rounded fullWidth>
            {t('common:SessionTimeLimitModal.continue')}
          </ContainedButton>
        </div>
      </div>
    </Modal>
  )
}

export default SessionTimeLimitModal
