import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Alert, alertService, AlertType } from '../../services/alert.service'
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import { FadeInOutDelayed } from '../../animations/common'
import useTranslation from 'next-translate/useTranslation'
import { Translate } from 'next-translate'

const getAlertIcon = (alert: Alert) => {
  switch (alert.type) {
    case AlertType.Info:
      return faInfoCircle
    case AlertType.Warning:
      return faExclamationCircle
    case AlertType.Success:
      return faCheckCircle
    case AlertType.Error:
      return faTimesCircle
  }
}

const getAlertColor = (alert: Alert) => {
  switch (alert.type) {
    case AlertType.Info:
      return '#3F66FB'
    case AlertType.Warning:
      return '#ffeb00'
    case AlertType.Success:
      return '#3DC13C'
    case AlertType.Error:
      return '#F13637'
  }
}

const getDefaultAlertTitle = (t: Translate, alert: Alert) => {
  switch (alert.type) {
    case AlertType.Info:
      return t('Alert.info')
    case AlertType.Warning:
      return t('Alert.warning')
    case AlertType.Success:
      return t('Alert.success')
    case AlertType.Error:
      return t('Alert.error')
  }
}

const AlertMobile = ({ alert, removeAlert }: { alert: Alert; removeAlert: (alert: Alert) => void }) => {
  const alertColor = getAlertColor(alert)

  return (
    <div key={alert.itemId} className={`alert-mobile flex flex-col bg-header text-headerContrast`}>
      <div className="alert-mobile-content flex justify-between items-center space-x-4 py-2 px-4">
        <div className="alert-mobile-content-icon text-xl" style={{ color: alertColor }}>
          <FontAwesomeIcon icon={getAlertIcon(alert)} />
        </div>
        <div className="alert-mobile-content-message flex flex-col flex-grow">
          <div className="text-sm">{alert.message}</div>
        </div>
        <div className="alert-mobile-content-close text-xl" onClick={() => removeAlert(alert)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>

      {alert.autoClose && (
        <div className="alert-mobile-progress flex w-full">
          <div className="alert-mobile-progress-inner flex justify-start h-1 w-full bg-transparent">
            <motion.div
              className={`alert-mobile-progress-indicator flex h-full w-full bg-[${alertColor}]`}
              style={{ backgroundColor: alertColor }}
              animate={{ width: 0, transition: { duration: alert.duration / 1000, ease: 'linear' } }}
            ></motion.div>
          </div>
        </div>
      )}
    </div>
  )
}

const AlertDesktop = ({ alert, removeAlert }: { alert: Alert; removeAlert: (alert: Alert) => void }) => {
  const { t } = useTranslation('common')
  const alertColor = getAlertColor(alert)
  const title = getDefaultAlertTitle(t, alert)

  return (
    <motion.div
      variants={FadeInOutDelayed}
      initial="initial"
      animate="animate"
      exit="exit"
      key={alert.itemId}
      className={`alert-desktop flex flex-col rounded-md shadow-2xl bg-background text-backgroundContrast border-[1px] border-neutral overflow-hidden`}
    >
      <div className="alert-desktop-wrapper relative flex flex-col">
        <div className="alert-desktop-inner flex flex-row justify-between items-center">
          <div className={`alert-desktop-icon text-4xl pl-4 py-4`} style={{ color: alertColor }}>
            <FontAwesomeIcon icon={getAlertIcon(alert)} />
          </div>

          <div className="alert-desktop-content flex grow h-full">
            <div className="alert-desktop-message flex items-start flex-col flex-grow justify-center text-sm px-4 py-6">
              <span className="alert-desktop-title font-bold">{title}</span>
              <span className="alert-desktop-description">{alert.message}</span>
            </div>

            <div
              className="alert-desktop-content-close flex items-center h-full justify-center border-l-[1px] border-neutral px-8 py-4 hover:bg-gray-500 hover:bg-opacity-10 cursor-pointer"
              onClick={() => removeAlert(alert)}
            >
              <span className="text-xxs font-semibold opacity-60 hover:opacity-100 transition">
                {t('Alert.close').toUpperCase()}
              </span>
            </div>
          </div>
        </div>

        {alert.autoClose && (
          <motion.div
            animate={{ width: 0, transition: { duration: alert.duration / 1000, ease: 'linear' } }}
            className={`alert-desktop-progress absolute bottom-0 left-0 w-full h-1`}
            style={{ backgroundColor: alertColor }}
          ></motion.div>
        )}
      </div>
    </motion.div>
  )
}

const AlertContainer = ({ alertContainerId = 'default-alert' }) => {
  const mounted = useRef(false)
  const router = useRouter()

  const [alerts, setAlerts] = useState([])
  const alertsRef = useRef(alerts)

  useEffect(() => {
    alertsRef.current = alerts
  }, [alerts])

  useEffect(() => {
    mounted.current = true

    // subscribe to new alert notifications
    const subscription = alertService.onAlert(alertContainerId).subscribe((alert: Alert) => {
      // if same alert exists already -> skip it
      if (alertsRef.current?.find((a) => a.message === alert.message)) return

      // clear alerts when an empty alert is received
      if (!alert.message) {
        setAlerts((alerts) => {
          // filter out alerts without 'keepAfterRouteChange' flag
          const filteredAlerts = alerts.filter((x) => x.keepAfterRouteChange)

          // remove 'keepAfterRouteChange' flag on the rest
          return omit(filteredAlerts, 'keepAfterRouteChange')
        })
      } else {
        // add alert to array with unique id
        alert.itemId = Math.random()
        setAlerts((alerts) => [...alerts, alert])

        // auto close alert if required
        if (alert.autoClose) {
          alert.duration = 7000
          setTimeout(() => removeAlert(alert), 7000)
        }
      }
    })

    // clear alerts on location change
    const clearAlerts = () => alertService.clear(alertContainerId)
    router.events.on('routeChangeStart', clearAlerts)

    return () => {
      mounted.current = false
      subscription.unsubscribe()
      router.events.off('routeChangeStart', clearAlerts)
    }
  }, [])

  const omit = (arr, key) => {
    return arr.map((obj) => {
      const { [key]: omitted, ...rest } = obj
      return rest
    })
  }

  const removeAlert = (alert) => {
    if (!mounted.current) return
    setAlerts((alerts) => alerts.filter((x) => x.itemId !== alert.itemId))
  }

  if (!alerts.length) return null

  return (
    <div className="alert-container flex justify-center w-full">
      <div className="alert-container-inner-mobile flex flex-col w-full max-w-appMaxWidth xl:hidden">
        <AnimatePresence>
          {alerts.map((alert: Alert) => (
            <AlertMobile alert={alert} removeAlert={removeAlert} key={alert.itemId} />
          ))}
        </AnimatePresence>
      </div>

      <div className="alert-container-inner-desktop hidden w-[400px] xl:flex flex-col space-y-4">
        <AnimatePresence>
          {alerts.map((alert: Alert) => (
            <AlertDesktop alert={alert} removeAlert={removeAlert} key={alert.itemId} />
          ))}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default AlertContainer
