import { formatMoney } from '../util/number'
import useUserCurrency from '../hooks/useUserCurrency'
import { useOpenBonusCount } from '../lib/account'
import Link from 'next/link'
import Trans from 'next-translate/Trans'
import { AnimatePresence, motion } from 'framer-motion'
import { FadeInOut } from '../animations/common'
import { useBetslipContext } from '../context/betslip.context'

const BonusBanner = ({ displayBetBonus = false, displayMarketingBonus = false }) => {
  const betslipContext = useBetslipContext()
  const currency = useUserCurrency()
  const { openBonusCount } = useOpenBonusCount()
  const betslipBonusAmount = betslipContext.gainBonus + betslipContext.stakeBonus
  const showBonusBanner = openBonusCount > 0 || betslipBonusAmount > 0

  const openBonusTranslation = (openBonusCount > 1) ? "common:BonusBanner.nOpenBonus.other" : "common:BonusBanner.nOpenBonus.one"

  return (
    <AnimatePresence>
      {showBonusBanner && (
        <motion.div
          className="bonus-banner flex justify-center items-center px-4 h-[48px] w-full skeleton !bg-gradient-to-r from-[#438D2C] via-[#72E54F] to-[#438D2C]"
          variants={FadeInOut}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div className="bonus-banner-text text-white text-sm whitespace-nowrap overflow-hidden overflow-ellipsis">
            {betslipBonusAmount > 0 && displayBetBonus ? (
              <Trans
                i18nKey="common:BonusBanner.betBonusApplied"
                values={{ betslipBonusAmount: formatMoney(betslipBonusAmount, currency) }}
                components={[<span className="font-bold mr-[2px]" />]}
              />
            ) : openBonusCount > 0 && displayMarketingBonus ? (
              <Link href="/account/bonus" className="w-full text-center">
                <Trans
                  i18nKey={ openBonusTranslation }
                  values={{ count: openBonusCount }}
                  components={[<span className="font-bold mx-[2px]" />]}
                />
              </Link>
            ) : (
              <></>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default BonusBanner
