import React from 'react'
import Link from 'next/link'
import { BackButton, ContainedButton, OutlineButton } from '../core/Button'
import { isAnonymousUser, isLoggedInCustomer, useUser, useUserAccounts } from '../../lib/user'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { motion, useReducedMotion } from 'framer-motion'
import StakeSelector from '../StakeSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StakeSelectorAnimation } from '../../animations/common'
import useScrollDirection from '../../hooks/useScrollDirection'
import Logo from './Logo'
import BonusBanner from '../BonusBanner'
import { useCasinoSettings, useFrontendSettings, useSportsBettingSettings } from '../../lib/content'

import AlertContainer from '../core/AlertContainer'
import useUserCurrency from '../../hooks/useUserCurrency'
import { formatMoney } from '../../util/number'
import useTranslation from 'next-translate/useTranslation'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import ConditionalLink from '../core/ConditionalLink'
import { useRouter } from 'next/router'
import UserAvatar from '../core/UserAvatar'
import { useLayoutContext } from '../../context/layout.context'

const MenuIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.3" height="16.233" viewBox="0 0 20.3 16.233">
      <g id="Gruppe_9574" data-name="Gruppe 9574" transform="translate(-337.017 -54.05)">
        <path
          id="Pfad_283"
          data-name="Pfad 283"
          d="M1,5H19.3"
          transform="translate(337.017 50.05)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Pfad_284"
          data-name="Pfad 284"
          d="M1,12H14.942"
          transform="translate(341.375 50.167)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Pfad_285"
          data-name="Pfad 285"
          d="M1,19H8.913"
          transform="translate(347.404 50.283)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}

export type SubHeaderProps = {
  className?: string
  title?: string
  backHref?: string
  backAction?: () => any
  AdditionalContent?: any
}

export const SubHeader = ({
  className = '',
  title = '',
  backHref = null,
  backAction = null,
  AdditionalContent = null,
}: SubHeaderProps) => {
  return (
    <div className={`sub-header flex flex-col justify-center items-center w-full overflow-hidden ${className}`}>
      <div className="sub-header-inner grid grid-cols-3 px-3 h-header w-full max-w-appMaxWidth bg-header text-headerContrast shadow-lg border-b-[1px] border-neutral">
        <div className="sub-header-inner-back flex justify-start items-center h-full">
          <BackButton url={backHref} _onClick={backAction} color="text-header-contrast" />
        </div>
        <div className="sub-header-inner-title flex justify-center items-center">
          <h1 className="font-bold whitespace-nowrap">{title}</h1>
        </div>
        <div className="sub-header-inner-additional flex justify-end items-center h-full pr-1">
          {AdditionalContent && AdditionalContent}
        </div>
      </div>
    </div>
  )
}

const HeaderNavItem = ({ title, href = null }) => {
  const router = useRouter()
  const isActiveRoute = href === router?.asPath || href === router?.pathname

  return (
    <ConditionalLink href={href}>
      <div
        className={`header-nav-item flex items-center justify-center h-full ${
          isActiveRoute ? 'border-b-4 border-primary pt-1' : ''
        } `}
      >
        <span
          className={`header-nav-item-title text-sm font-semibold whitespace-nowrap cursor-pointer uppercase ${
            isActiveRoute ? 'header-nav-item-title-active text-primary' : 'text-headerContrast opacity-60'
          } hover:opacity-100 transition`}
        >
          {title}
        </span>
      </div>
    </ConditionalLink>
  )
}

const HeaderNav = React.memo(() => {
  const { t } = useTranslation('common')
  const { user } = useUser()
  const casinoSettings = useCasinoSettings()
  const sportsBettingsSettings = useSportsBettingSettings()

  return (
    <div className="header-nav flex justify-self-center h-full">
      <div className="header-nav-inner hidden xl:flex items-center h-full">
        <nav className="header-nav-inner-items flex items-center space-x-8 w-full h-full">
          <HeaderNavItem title={t('Header.sports')} href="/sports" />
          {sportsBettingsSettings?.liveEnabled && <HeaderNavItem title={t('Header.live')} href="/sports/live" />}
          {casinoSettings?.enabled &&
            ((casinoSettings?.visibleToAnonymous && isAnonymousUser(user)) ||
              (isLoggedInCustomer(user) && user?.betPlacementAndGamingAllowed)) && (
              <HeaderNavItem title={t('Header.casino')} href="/casino" />
            )}
        </nav>
      </div>
    </div>
  )
})

const HeaderLogo = React.memo(() => {
  return (
    <div className="header-logo flex justify-start items-center pl-4 w-full h-full">
      <Link href="/sports" className="flex">
        <Logo className="!justify-start" height="32px" backgroundColorVariableName={'--header'} animate={false} />
      </Link>
    </div>
  )
})

const HeaderUserBalance = () => {
  const { t } = useTranslation('common')
  const { user } = useUser()
  const { mainAccount } = useUserAccounts()
  const currency = useUserCurrency()
  const loggedInCustomer = isLoggedInCustomer(user)
  const sportsBettingSettings = useSportsBettingSettings()

  const [balanceVisible, setBalanceVisible] = useLocalStorage<boolean>('balanceVisible', true)

  return (
    <>
      {mainAccount?.balance <= 0.01 ? (
        <div className="header-user-balance px-[10px]">
          <Link href={'/payment/deposit'}>
            <ContainedButton className="header-user-balance-deposit-button !px-2 !py-[6px]" rounded>
              {t('Header.deposit')}
            </ContainedButton>
          </Link>
        </div>
      ) : (
        <>
          {loggedInCustomer && sportsBettingSettings?.showBalance && (
            <div className="header-user-balance flex space-x-2 items-center px-[10px]">
              <div
                className={`header-user-balance-inner flex flex-col justify-center items-end transition-all ${
                  balanceVisible ? '' : 'header-user-balance-invisible blur-sm'
                }`}
                onClick={() => setBalanceVisible(!balanceVisible)}
              >
                <span className="header-user-balance-label text-xxs opacity-60">{t('Common.balance')}</span>
                <span className="header-user-balance-value text-xs leading-3 font-semibold">
                  {formatMoney(mainAccount?.balance, currency)}
                </span>
              </div>
              <Link href={'/payment/deposit'}>
                <ContainedButton className="header-user-balance-deposit-button !px-2 !py-[6px]" rounded>
                  {t('Header.deposit')}
                </ContainedButton>
              </Link>
            </div>
          )}
        </>
      )}
    </>
  )
}

const HeaderUserAvatar = () => {
  const { user } = useUser()
  const loggedInCustomer = isLoggedInCustomer(user)

  return (
    <>
      {loggedInCustomer && (
        <div className="header-user-avatar hidden md:flex items-center space-x-2 px-[10px]">
          <Link href="/account">
            <UserAvatar height="40px" width="40px" />
          </Link>
        </div>
      )}
    </>
  )
}

const HeaderSearch = () => {
  const sportsBettingSettings = useSportsBettingSettings()

  return (
    <>
      {sportsBettingSettings.searchEnabled && (
        <div className="header-search flex justify-center items-center h-full px-[10px]">
          <Link
            href="/sports/search"
            className="flex justify-center items-center h-full w-full"
            aria-label="Search for sports, leagues and events"
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-xl" />
          </Link>
        </div>
      )}
    </>
  )
}

const HeaderMenu = ({ displayUserSection }) => {
  const { t } = useTranslation()
  const layoutContext = useLayoutContext()
  const frontendSettings = useFrontendSettings()
  const { user } = useUser()
  const loggedInCustomer = isLoggedInCustomer(user)

  return (
    <>
      {loggedInCustomer ? (
        <div
          className="header-menu flex justify-center items-center h-full pl-[10px] pr-4"
          onClick={() => layoutContext.setSideNavActive(true)}
        >
          <MenuIcon />
        </div>
      ) : (
        <div className="header-menu flex justify-center items-center h-full pl-[10px] pr-2">
          {displayUserSection && (
            <div className="header-menu-auth-desktop flex space-x-2 items-center">
              <Link href="/account/login">
                <OutlineButton
                  rounded
                  borderColor="border-gray-500"
                  className="header-menu-auth-desktop-login !px-2 !py-[6px]"
                >
                  {t('common:Header.login')}
                </OutlineButton>
              </Link>

              {!frontendSettings?.disableRegistrations && (
                <Link href="/account/register">
                  <ContainedButton rounded className="header-menu-auth-desktop-register !px-2 !py-[6px]">
                    {t('common:Header.register')}
                  </ContainedButton>
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

const HeaderAnimatedStakeSelector = ({ hideStakeSelectorOnScroll, addBonusBanner }) => {
  const [scrollDirection, scrollOffset] = useScrollDirection(48)
  const showStakeSelector = !hideStakeSelectorOnScroll || scrollDirection !== 'down' || (scrollOffset as number) <= 48
  const shouldReduceMotion = useReducedMotion()

  return (
    <motion.div
      variants={StakeSelectorAnimation}
      animate={showStakeSelector || shouldReduceMotion ? 'animate' : 'exit'}
      className="header-animated-stake-selector flex md:hidden flex-col w-full"
    >
      <StakeSelector />
      {addBonusBanner && <BonusBanner displayBetBonus displayMarketingBonus />}
      <AlertContainer />
    </motion.div>
  )
}

export type HeaderProps = {
  displayUserSection?: boolean
  addStakeSelector?: boolean
  addBonusBanner?: boolean
  hideStakeSelectorOnScroll?: boolean
}

const Header = ({
  displayUserSection = true,
  addStakeSelector = false,
  addBonusBanner = false,
  hideStakeSelectorOnScroll = true,
}: HeaderProps) => {
  return (
    <>
      <div className={`header-wrapper flex justify-center items-center flex-col w-full overflow-hidden`}>
        <div className="header-wrapper-inner flex flex-col items-center w-full">
          <header className="header flex justify-center w-full h-header bg-header border-b-[1px] border-neutral shadow-lg z-[2]">
            <div className="header-inner grid grid-cols-[1fr_auto_1fr] items-center h-full w-full text-headerContrast max-w-appMaxWidth">
              <HeaderLogo />
              <HeaderNav />
              <div className="header-inner-items flex justify-self-end items-center h-full cursor-pointer">
                <HeaderUserBalance />
                <HeaderUserAvatar />
                <HeaderSearch />
                <HeaderMenu displayUserSection={displayUserSection} />
              </div>
            </div>
          </header>

          {addStakeSelector && (
            <div className="stake-selector-wrapper flex w-full justify-center xl:hidden z-[1]">
              <div className="stake-selector-wrapper-inner flex w-full max-w-appMaxWidth">
                <HeaderAnimatedStakeSelector
                  hideStakeSelectorOnScroll={hideStakeSelectorOnScroll}
                  addBonusBanner={addBonusBanner}
                />

                <div className="hidden md:flex flex-col w-full">
                  <StakeSelector />
                  {addBonusBanner && <BonusBanner displayBetBonus displayMarketingBonus />}
                  <AlertContainer />
                </div>
              </div>
            </div>
          )}

          {!addStakeSelector && (
            <div className="additional-items-wrapper flex flex-col w-full justify-center xl:hidden z-[1]">
              {addBonusBanner && (
                <div className="additional-item-bonus-banner flex w-full justify-center">
                  {addBonusBanner && <BonusBanner displayBetBonus displayMarketingBonus />}
                </div>
              )}

              <div className="additional-item-alert-container flex w-full justify-center">
                <AlertContainer />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Header
