import { useEffect, useRef, useState } from 'react'
import { useBetslipContext } from '../context/betslip.context'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useSportsBettingSettings } from '../lib/content'
import useUserCurrency from '../hooks/useUserCurrency'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRepeat } from '@fortawesome/pro-duotone-svg-icons'
import { BetTypes } from '../services/bet/bet.models'
import { floorNumber } from '../util/number'

type StakeSelectorManualInputProps = {
  defaultStakes: number[]
  isSelected: boolean
  currencySymbol: string
  disabled: boolean
}

const StakeSelectorManualInput = ({
  defaultStakes,
  isSelected,
  currencySymbol,
  disabled,
}: StakeSelectorManualInputProps) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const [manualStake, setManualStake] = useState(null)
  const stakeInput = useRef(null)

  useEffect(() => {
    if (!defaultStakes.includes(betslipContext.stake)) setManualStake(betslipContext.stake)
    else setManualStake(null)
  }, [betslipContext.stake, defaultStakes])

  const onStakeChange = (event: any) => {
    setManualStake((event?.target?.value as string)?.replace(',', '.') as any)
  }

  const onBlur = async () => {
    if (!isNaN(manualStake) && manualStake > 0) {
      const stakeSet = await betslipContext.setStake(
        floorNumber(manualStake),
        betslipContext.betType === BetTypes.Combi ? 'totalStake' : 'stakePerBet'
      )
      if (!stakeSet) setManualStake(betslipContext.stake)
    } else {
      setManualStake(defaultStakes.includes(betslipContext.stake) ? null : betslipContext.stake)
    }
  }

  const onFocusEvent = (event: any) => {
    setManualStake(null)
    event?.target?.select()
  }

  const onKeyDownEvent = (event: any) => {
    if (event.key === 'Enter') event?.target?.blur()
  }

  const focusStakeInput = () => {
    stakeInput.current.focus()
  }

  return (
    <div
      className={`stake-selector-item-manual ${
        isSelected
          ? 'stake-selector-item-manual-active bg-stakeSelectorItemActive text-stakeSelectorItemActiveContrast !border-b-primary'
          : 'bg-stakeSelectorItem text-stakeSelectorItemContrast'
      } p-1 pl-1 pr-2 flex items-center justify-center text-xs h-full border-r-[1px] border-neutral border-b-2 relative`}
      onClick={focusStakeInput}
    >
      <span className="stake-selector-item-manual-label absolute top-0 left-1 text-xxs font-semibold opacity-60">
        {betslipContext.betType === BetTypes.Single ? t('BetSlip.stakePerBet') : t('BetSlip.stake')}
      </span>
      <input
        ref={stakeInput}
        className={`stake-selector-item-manual-input flex justify-end bg-transparent border-none outline-none w-full text-right ${
          !isSelected ? 'opacity-70' : 'font-semibold'
        }`}
        value={manualStake ?? ''}
        placeholder={'...'}
        onChange={onStakeChange}
        onFocus={onFocusEvent}
        onKeyDown={onKeyDownEvent}
        onBlur={onBlur}
        inputMode="decimal"
        disabled={disabled}
      />
      <span>&nbsp;</span>
      <span className={`stake-selector-item-manual-currency ${!isSelected ? 'opacity-70' : 'font-semibold'}`}>
        {currencySymbol}
      </span>
    </div>
  )
}

type StakeSelectorItemProps = {
  amount: number
  setStake: (amount: number) => void
  isSelected: boolean
  currencySymbol: string
}

const StakeSelectorItem = ({ amount, setStake, isSelected, currencySymbol }: StakeSelectorItemProps) => {
  return (
    <div
      className={`stake-selector-item flex justify-center items-center !px-2 text-xs h-full border-neutral border-b-2 border-r-[1px] last:border-r-0 transition-colors ${
        isSelected
          ? 'stake-selector-item-active bg-stakeSelectorItemActive text-stakeSelectorItemActiveContrast !border-b-primary font-semibold'
          : 'bg-stakeSelectorItem text-stakeSelectorItemContrast'
      }`}
      onClick={() => setStake(amount)}
    >
      <span
        className={`stake-selector-item-label flex items-center whitespace-nowrap ${
          !isSelected ? 'stake-selector-item-label-inactive opacity-70' : ''
        }`}
      >
        {currencySymbol}
        {amount}
      </span>
    </div>
  )
}

const BetTypeSelectorItem = ({ isActive, label }) => {
  return (
    <span
      className={`bet-type-selector-item ${
        isActive ? 'bet-type-selector-item-active text-sm font-semibold' : 'text-xxs font-bold !opacity-60'
      }`}
    >
      {label}
    </span>
  )
}

const BetTypeSelector = ({}) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const activeBetType = betslipContext.betType
  const [rotateDegree, setRotateDegree] = useState(0)

  const onChangeBetType = () => {
    switch (betslipContext.betType) {
      case BetTypes.Single:
        betslipContext.setBetType(BetTypes.Combi)
        break
      case BetTypes.Combi:
        betslipContext.setBetType(BetTypes.Single)
        break
      case BetTypes.System:
        betslipContext.setBetType(BetTypes.Combi)
        break
    }

    if (rotateDegree === 0) {
      setRotateDegree(360)
    } else if (rotateDegree === 360) {
      setRotateDegree(0)
    }
  }

  return (
    <div
      className="bet-type-selector flex items-center justify-center h-full w-full px-2 space-x-1 bg-stakeSelectorItem text-stakeSelectorItemContrast border-r-[1px] border-neutral border-b-2"
      onClick={onChangeBetType}
    >
      <div className="bet-type-selector-inner flex flex-col flex-grow items-center whitespace-nowrap overflow-hidden">
        {activeBetType === BetTypes.Single && (
          <BetTypeSelectorItem isActive={true} label={t('BetSlip.betTypes.single')} />
        )}

        {activeBetType === BetTypes.Combi && (
          <BetTypeSelectorItem isActive={true} label={t('BetSlip.betTypes.combi')} />
        )}

        {activeBetType === BetTypes.System && (
          <BetTypeSelectorItem isActive={true} label={t('BetSlip.betTypes.system')} />
        )}

        {activeBetType === BetTypes.Combi && (
          <BetTypeSelectorItem isActive={false} label={t('BetSlip.betTypes.single')} />
        )}

        {(activeBetType === BetTypes.Single || activeBetType === BetTypes.System) && (
          <BetTypeSelectorItem isActive={false} label={t('BetSlip.betTypes.combi')} />
        )}
      </div>

      <FontAwesomeIcon
        icon={faArrowsRepeat}
        className={`bet-type-selector-icon transition-transform duration-500`}
        style={{ transform: `rotate(${rotateDegree}deg)` }}
      />
    </div>
  )
}

const StakeSelector = ({ disabled = false }) => {
  const sportsBettingSettings = useSportsBettingSettings()
  const betslipContext = useBetslipContext()
  const currency = useUserCurrency()
  const currencySymbol = currency?.symbol ?? currency?.shortSign
  const betTypeSelectorActive =
    betslipContext.isBetTypeAcitvated(BetTypes.Single) && betslipContext.isBetTypeAcitvated(BetTypes.Combi)

  const [defaultStakes, setDefaultStakes] = useState([1, 5, 10])

  useEffect(() => {
    if (sportsBettingSettings != null) {
      setDefaultStakes([
        parseInt(sportsBettingSettings.defaultStake1),
        parseInt(sportsBettingSettings.defaultStake2),
        parseInt(sportsBettingSettings.defaultStake3),
      ])
    }
  }, [sportsBettingSettings])

  const setStake = (stake: number) => {
    if (disabled) return
    betslipContext.setStake(stake, betslipContext.betType === BetTypes.Combi ? 'totalStake' : 'stakePerBet')
  }

  return (
    <div
      className={`stake-selector grid ${
        betTypeSelectorActive
          ? 'grid-cols-[minmax(100px,1fr)_minmax(90px,1fr)_1fr_1fr_1fr]'
          : 'grid-cols-[minmax(90px,1fr)_1fr_1fr_1fr]'
      }  items-center h-[48px] bg-stakeSelector`}
    >
      {betTypeSelectorActive && <BetTypeSelector />}

      <StakeSelectorManualInput
        defaultStakes={defaultStakes}
        isSelected={!defaultStakes.includes(betslipContext.stake)}
        currencySymbol={currencySymbol}
        disabled={disabled}
      />

      {defaultStakes?.map((x, index) => (
        <React.Fragment key={index}>
          <StakeSelectorItem
            amount={x}
            setStake={setStake}
            isSelected={betslipContext.stake === x}
            currencySymbol={currencySymbol}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

export default React.memo(StakeSelector)
